import React from "react";
import ContactPopup from "../components/contactPopup/ContactPopup";
import WidgetTool from "../components/widgetTool/WidgetTool";
import "../css/Commons.css";
import "./Instrumentos.css";

import { connect } from "react-redux";

function Instrumentos() {

    return (
        <div className="page-container">
        <hr className="separator" />
        <div className="tools-form">
            <h1 className="orange">Instrumentos</h1>
            
            <div className="subtitle-container">
            <h2 className="blue">Gestión de proyectos y preparación para certificaciones</h2>
            </div>

            <ContactPopup />

            <div className="widget-placeholder">
                <WidgetTool
                    title="Assessment de Salud del Proyecto"
                    description="Herramienta que permitirá medir el desempeño del proyecto y 
                    evaluarlo de una forma integral."
                    hasDisccount={false}
                    redirecTo="saludProyecto"
                    disabled={false}
                />

                <WidgetTool
                    title="Assessment de Director de Proyecto"
                    description="El Assessment le permitirá autoevaluar y determinar las 
                    habilidades del Director de Proyecto."
                    hasDisccount={false}
                    redirecTo="directorProyecto"
                    disabled={false}
                />

                <WidgetTool
                    title="Assessment de Cronograma del Proyecto"
                    description="Assessment que permite determinar el correcto desarrollo del 
                    cronograma de trabajo de un proyecto."
                    hasDisccount={false}
                    redirecTo="madurezCronograma"
                    disabled={false}
                />

                <WidgetTool
                    title="Herramienta de Priorización de Proyectos"
                    description="Herramienta que permitira medir el nivel de prioridad de un Proyecto
                    y a su vez facilitando la tarea de selección de un proyecto previo a su comienzo."
                    hasDisccount={false}
                    redirecTo="priorization/result"
                    disabled={false}
                />

                    <WidgetTool
                        title="Herramienta de Estilo de Liderazgo"
                        description="Herramienta que permitira medir el nivel de Liderazgo que posee un individuo
                        facilitando la tarea de encontrar sus puntos debiles al momento de llevar a cabo un proyecto."
                        hasDisccount={false}
                        redirecTo="estiloLiderazgo"
                        disabled={false}
                    />

                    <WidgetTool
                        title="Herramienta de Nivel de Madurez del Cronograma"
                        description="Esta herramienta permite evaluar el nivel de madurez de un cronograma de proyecto,
                        facilitando la medición de la prioridad de un proyecto antes de su inicio. 
                        Su objetivo es optimizar el proceso de selección de proyectos, 
                        asegurando que se tomen decisiones informadas sobre cuáles proyectos deben iniciarse primero, basándose en la preparación y viabilidad de su cronograma."
                        hasDisccount={false}
                        redirecTo="madurezCronograma"
                        disabled={false}
                    />
                
            </div>
        </div>
            
        </div>
    );
}

export default connect()(Instrumentos);