import { useState, forwardRef, Children } from "react";
import { Card, Col, Badge, Form, Row, Dropdown, ButtonGroup, Button } from "react-bootstrap";
import './TodoListForm.css'
import { SingleDatePicker } from "react-dates";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import regexValidator from "../../libs/regexValidator";

const TodoListForm = ({ setToDos, labels = [], setLabels = () => { }, addTaskCallback }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")
    const [prioridad, setPrioridad] = useState("");
    const [label, setLabel] = useState("")
    const [date, setDate] = useState(null);
    const [focus, setFocus] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        const dateTemp = moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")
        const newTodo = { id: uuidv4(), title, description, prioridad, label, done: false, dueDate: dateTemp }

        if (addTaskCallback === undefined ) {
            setToDos(prevTodos => {
                const newTodos = [...prevTodos, newTodo]
                return newTodos.sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate)).reverse();
            })
        }else{
            addTaskCallback(newTodo)
        }

        cleanForm()
    }

    const cleanForm = () => {
        setTitle('')
        setDescription('')
        setPrioridad('')
        setDate(null)
        setLabel('')
    }

    const enableSubmit = () => {
        return title && description && prioridad
    }

    return (
        <Form onSubmit={e => handleSubmit(e)}>
            <Card className="todo-form">
                <Form.Group controlId="title" className="mb-0" >
                    <Form.Control
                        className="col-md-auto"
                        style={{ border: "0px" }}
                        placeholder="Titulo"
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className controlId="descripcion">
                    <Form.Control
                        placeholder="Descripcion"
                        type="textarea"
                        value={description}
                        onChange={e => setDescription(e.target.value)} />
                </Form.Group>
                <Card.Footer>
                    <Row className="w-100 d-inline-flex flex-row align-self-center">
                        <Col className="col-md-auto">
                            <SingleDatePicker
                                placeholder='Ingrese La fecha'
                                block={true}
                                date={date} // momentPropTypes.momentObj or null
                                onDateChange={date => setDate(date)} // PropTypes.func.isRequired
                                focused={focus} // PropTypes.bool
                                onFocusChange={({ focused }) => { setFocus(focused) }} // PropTypes.func.isRequired
                                id={`component-${uuidv4()}`} // PropTypes.string.isRequired,
                                style={{
                                    fontFamily: 'Open Sans, sans-serif',
                                }}
                            />
                        </Col>
                        <Col className="col-md-auto">
                            <Dropdown as={ButtonGroup} onSelect={value => { setPrioridad(value); console.log(value) }}>
                                <Dropdown.Toggle variant="outline-primary">Prioridad</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey={'L'} active={prioridad === 'L'}><i className="bi bi-flag text-success mr-1"></i>Baja</Dropdown.Item>
                                    <Dropdown.Item eventKey={'M'} active={prioridad === 'M'}><i className="bi bi-flag text-warning mr-1"></i>Media</Dropdown.Item>
                                    <Dropdown.Item eventKey={'H'} active={prioridad === 'H'}><i className="bi bi-flag text-danger mr-1"></i>Alta</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col className="col-md-auto">
                            {label ?
                                (<div className="m-1">
                                    <Badge className="badge h-100" variant="secondary" onClick={e => setLabel('')}><i className="bi bi-tag" />
                                        {label}</Badge>
                                </div>) :
                                (<Dropdown as={ButtonGroup} onSelect={value => { setLabel(value); console.log(value) }}>
                                    <Dropdown.Toggle variant="outline-primary">Etiquetas</Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu} labels={labels} setLabels={setLabels}>
                                        {
                                            labels.map((item, idx) => (
                                                <Dropdown.Item key={idx} eventKey={item}>{item}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>)}
                        </Col>
                        <Col>
                        </Col>
                        <Col className="col-md-auto">
                            <Button variant="outline-primary" onClick={e => { cleanForm() }}>Cancelar</Button>
                        </Col>
                        <Col className="col-md-auto pr-0">
                            <Button type="submit" disabled={!enableSubmit()}>Crear Tarea</Button>
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Form>
    )
}


// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, labels, setLabels }, ref) => {
        const [value, setValue] = useState('');
        const [notExists, setNotExists] = useState(null)

        const handleOnChange = (value) => {
            setNotExists(!labels.includes(value))
            setValue(value.trim())
        }

        const handleOnKeyDown = (e) => {
            if (value && notExists && (e.key === 'Enter' || e.key === ' ')) {
                e.preventDefault()
                setLabels(prev => [...labels, value.toLowerCase()])
                setValue('')
            }
        }

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Escriba para filtrar..."
                    // onChange={(e) => handleOnChange(e.target.value)}
                    onChange={e => regexValidator(e, /^[a-z]+$/g, value => handleOnChange(value))}
                    onKeyDown={handleOnKeyDown}
                    value={value}
                />
                <ul className="list-unstyled">
                    {Children.toArray(children).filter(
                        (child) => {
                            return !value || child.props.children.toLowerCase().startsWith(value)
                        },
                    )}
                </ul>
            </div>
        );
    },
);

export default TodoListForm;