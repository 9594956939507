/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import "./Desktop.css";
import Menu  from "../components/menu/Menu";
import TopMenu from "../components/topMenu/TopMenu";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "../reducers/session";
import { actions as routesActions } from "../reducers/routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Desktop({ dispatch, component, isAuthenticated, ...props }) {

  useEffect(() => {
    function validateSession(){
      if (isAuthenticated != null) {
        if (isAuthenticated === false) {
         dispatch(routesActions.goTo(""));
       }
     }
    }

    validateSession();
  }, [isAuthenticated]);

    const getLayout = () => {
        const ParentContainer = component;

        return ( 
            <div className="Desktop">
            <div className="float-left left-menu">
                <Menu></Menu>
            </div>

            <div className="float-left main-container">
                <TopMenu></TopMenu>

                {component !== undefined && component !== null && <ParentContainer />}
            </div>

            <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} />
            </div>
        );
    }

    return (
        <Route
                {...props}
                render={() => {
                    return <>{getLayout()}</>;
                }}
            />
    );
}

const mapStateToProps = state => ({
  isAuthenticated: sessionSelectors.getIsAuthenticated(state),
});

export default connect(mapStateToProps)(Desktop);