import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
    Button,
    Form,
    Card, ButtonGroup
} from "react-bootstrap";
import useEditField from "./useEditField";
import './Task.css'

const Task = ({
    statusId,
    task,
    index,
    draggable = true,
    autoFocus = false,
    createTask,
    deleteTask,
    editTask,
}) => {
    const {
        field,
        isEditing,
        setIsEditing,
        setField,
        inputRef,
        handleBlur,
        handleChange,
        onKeyPressed,
    } = useEditField({
        autoFocus,
        fieldId: task?.id,
        onCreate: (field) =>
            createTask({
                statusId,
                content: field,
            }),
        onEdit: (id, field) =>
            editTask({
                id: id,
                content: field,
            }),
    });
    const [actionHovered, setActionHovered] = useState(false)
    const [cardHovered, setCardHovered] = useState(false)

    useEffect(() => {
        if (autoFocus && inputRef?.current) inputRef.current.focus();
    }, [autoFocus, inputRef]);

    const renderEditingInput = () => {
        return (
            <Form.Control
                onBlur={handleBlur}
                onClick={(e) => e.stopPropagation()}
                ref={inputRef}
                value={field}
                onKeyPress={onKeyPressed}
                onChange={handleChange}
                placeholder="Tarea"
                size="sm"
                as="textarea"
            />
        );
    };

    const renderTaskContent = ({ dragHandleProps = {} }) => {
        return (
            <Card className="kanban-task mt-2 mb-2" {...dragHandleProps}
                onMouseEnter={(e) => { e.preventDefault(); setCardHovered(true) }} onMouseLeave={() => { setCardHovered(false) }}
            >

                <Card.Body>
                    {
                        isEditing || <div className="float-right">
                            {renderMenu()}
                        </div>
                    }
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="flex-fill">
                            {isEditing || autoFocus ? (
                                renderEditingInput()
                            ) : (
                                <p style={{ userSelect: "none" }}>{task?.content}</p>
                            )}
                            {task?.priority && task?.priority !== "none" && (
                                <p priority={task.priority} style={{ marginTop: "8px" }}>
                                    {task.priority}
                                </p>
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>
        );
    };

    const renderMenu = () => {
        return (
            <ButtonGroup className={`${cardHovered ? 'visible' : 'invisible'}`} aria-label="actions" >
                <Button className={`edit ${actionHovered === 'edit'? 'bg-warning': ''}`}
                    variant="outline-secondary"
                    size="sm"
                    onMouseEnter={(e) => { e.preventDefault(); setActionHovered('edit'); }}
                    onMouseLeave={() => { setActionHovered(null) }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsEditing(true);
                        setField(task?.content || "");
                    }}>
                    <i className="bi bi-pencil-square" ></i>
                </Button>
                <Button className={`delete ${actionHovered === 'delete'? 'bg-danger': ''}`}
                    variant="outline-secondary"
                    size="sm"
                    onMouseEnter={(e) => { e.preventDefault(); setActionHovered('delete') }}
                    onMouseLeave={() => { setActionHovered(null) }}
                    onClick={() => deleteTask({ id: task.id })}
                    >
                    <i className="bi bi-trash"></i>
                </Button>
            </ButtonGroup>
        );
    };

    return (
        <Draggable
            draggableId={task?.id || "test"}
            index={index}
            isDragDisabled={!draggable}
        >
            {({ draggableProps, dragHandleProps, innerRef }) => (
                <div
                    style={{
                        backgroundColor: "white",
                        marginBottom: "8px",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        borderRadius: "4px",
                    }}
                    {...draggableProps}
                    ref={innerRef}
                >
                    {renderTaskContent({ dragHandleProps })}
                </div>
            )}
        </Draggable>
    );
};

export default React.memo(Task);