export const statesFromChar = (char)=>{
    switch(char){
        case 'S':
            return 'Iniciado';
        case 'C':
            return 'Creado';
        default:
            return 'Creado';
    }
}
    

    
