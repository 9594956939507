import React, { useRef, useState } from "react";
import { Button, Form, Row, Col, Card, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { actions, selectors } from "../../reducers/project";
import { connect ,useSelector, useDispatch } from 'react-redux';
// import { connect } from "react-redux";
import { toast } from "react-toastify";
import './analisisImpacto.css';

export const AnalisisAmbiental = ({ showNotification }) => {
    const dispatch = useDispatch();
    const { id } = useParams(); // Extrae el id de los parámetros de la ruta
    const initialCriteria = [
        { criterio_id: 1, text: 'Los productos pueden ser reciclados.', weight: 0.3 },
        { criterio_id: 2, text: 'Los productos son energéticamente eficientes.', weight: 0.1 },
        { criterio_id: 3, text: 'El producto es perjudicial para el medio ambiente.', weight: 0.2 },
        { criterio_id: 4, text: 'Tiene una política de gestión ambiental activa.', weight: 0.1 },
        { criterio_id: 5, text: 'Posible impacto negativo por residuos sólidos.', weight: 0.1 },
        { criterio_id: 6, text: 'Posible impacto negativo por residuos líquidos.', weight: 0.1 },
        { criterio_id: 7, text: 'Posible impacto negativo por residuos peligrosos.', weight: 0.1 },
        { criterio_id: 8, text: 'Posible impacto negativo en la vida de la sociedad.', weight: 0.1 },
        { criterio_id: 9, text: 'Posible de daño a la imagen.', weight: 0.1 }
    ];

    const [criterios, setCriterios] = useState(initialCriteria);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const [criteriosCalificados, setCriteriosCalificados] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleSelectQuestion = (index) => {
        setSelectedQuestionIndex(index);
    };

    const handleCalificar = (calificacion) => {
        const criterio_id = criterios[selectedQuestionIndex].criterio_id;
        setCriteriosCalificados({
            ...criteriosCalificados,
            [criterio_id]: calificacion
        });
    };

    const handleGuardarAnalisis = (event) => {
        event.preventDefault(); // Evita que el formulario se envíe de forma predeterminada

        // Validar que todos los campos estén calificados y dentro del rango de 0 a 4
        const invalidFields = criterios.filter((criterio) => {
            const calificado = criteriosCalificados[criterio.criterio_id];
            return calificado === undefined || calificado < 0 || calificado > 4;
        });

        if (invalidFields.length > 0) {
            setModalMessage("Por favor, asegúrese de calificar todos los criterios con un valor entre 0 y 4.");
            setShowModal(true); // Mostrar modal de error
            return;
        }

        // Crear el payload basado en los criterios calificados
        let payload = criterios.map((criterio) => {
            const calificado = criteriosCalificados[criterio.criterio_id] || 0; // Si no está calificado, se asigna un 0
            return {
                criterio_id: criterio.criterio_id,
                proyecto_id: id, // Aquí usamos el id extraído de la URL
                weight: criterio.weight.toFixed(2),
                rating: calificado, // Se asigna la calificación correspondiente
            };
        });

        // Realizar el dispatch para actualizar el análisis ambiental
        dispatch(actions.createAnalisisAmbientalRequest({ payload }));
        // Si es necesario, puedes agregar un mensaje de éxito o cualquier lógica adicional aquí
        toast.success('Análisis guardado correctamente');
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <Form>
                <h2 className="mb-4 text-center">Modelo de Decisión Ambiental</h2>
                <Row className="mb-4 justify-content-center">
                    {criterios.map((item, index) => (
                        <Col key={index} md={1} className="text-center">
                            <Button
                                variant={criteriosCalificados[item.criterio_id] ? 'secondary' : 'outline-primary'}
                                onClick={() => handleSelectQuestion(index)}
                                className="p-3 rounded-circle shadow-sm"
                                disabled={criteriosCalificados[item.criterio_id]}
                            >
                                {index + 1}
                            </Button>
                        </Col>
                    ))}
                </Row>

                <Row className="align-items-center justify-content-center">
                    <Col md={6} className="mb-4">
                        <div className="p-4 border rounded-lg shadow bg-white">
                            <Form.Label className="mb-2 text-secondary">
                                <strong>{criterios[selectedQuestionIndex].text}</strong>
                            </Form.Label>
                            <Form.Group>
                                <Form.Label className="small text-muted">Calificación:</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="0"
                                    max="4"
                                    value={criteriosCalificados[criterios[selectedQuestionIndex].criterio_id] || ''}
                                    onChange={(e) => handleCalificar(e.target.value)}
                                    placeholder="0 - 4"
                                    className="text-center border-success rounded-pill"
                                />
                            </Form.Group>
                        </div>
                    </Col>

                    <Col md={3} className="mb-4">
                        <Card className="border-info">
                            <Card.Header className="bg-info text-white text-center">
                                <strong>Leyenda de Puntuación</strong>
                            </Card.Header>
                            <Card.Body>
                                <table className="table table-sm table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '70%' }}>Descripción</th>
                                            <th className="text-center" style={{ width: '30%' }}>Puntuación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>NO</td>
                                            <td className="text-center">0</td>
                                        </tr>
                                        <tr>
                                            <td>Aún no, pero trabajando en ello</td>
                                            <td className="text-center">1</td>
                                        </tr>
                                        <tr>
                                            <td>Algo</td>
                                            <td className="text-center">2</td>
                                        </tr>
                                        <tr>
                                            <td>Casi completo</td>
                                            <td className="text-center">3</td>
                                        </tr>
                                        <tr>
                                            <td>Sí</td>
                                            <td className="text-center">4</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Button
                    variant="primary"
                    onClick={handleGuardarAnalisis}
                    disabled={isLoading}
                >
                    {isLoading ? 'Guardando...' : 'Guardar Análisis'}
                </Button>
            </Form>

            {/* Modal de error */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Advertencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    showNotification: state.AnalisisAmbiental.showNotification
});

export default connect(mapStateToProps)(AnalisisAmbiental);
